import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Dropdown } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { API } from '../api/API';

const EditUser = () => {
  const param = useParams(); // Get user ID from URL
  const navigate = useNavigate();
  const api = new API();

  const [roles, setRoles] = useState([]);
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [password, setPassword] = useState('');
  let userid = param.userid;

  useEffect(() => {
    async function getrole() {
      const response = await api.roles_get_all_role();
      setRoles(response)
    }
    console.log(roles,"roles");
    

    // Fetch user data  with role for editing
    async function getuser_by_id() {
      const response = await api.get_user_roles_by_id(userid);
      const userData = response;
      setName(userData.name);
      setEmail(userData.email);
      setUsername(userData.username);
      setSelectedRole(userData.role[0]); // Assuming role_id is returned
      setStatus(userData.status);
      setPassword(userData.password);
    }


  getrole();
  getuser_by_id();
}, []);
// console.log(password);
const handleSelect = (selectedStatus) => {
    setStatus(selectedStatus);
  };



const handleSubmit = async(event) => {
  event.preventDefault();
  const data = { name, email, username, role: selectedRole, status };
  console.log(data);

  const response = await api.update_user(data,userid,navigate);
};

const handleBackSubmit = () => {
  navigate("/users");
};

const selectedRoleName = roles.find(role => role.id === selectedRole)?.name || "Select a role";
// console.log(selectedRoleName);


return (
  <div className='basic' style={{ position: 'relative', marginTop: '40px' }}>
    <Container style={{ width: '55%' }}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formRole">
              <Form.Label>Role</Form.Label>
              <Dropdown>
                <Dropdown.Toggle
                  variant="dark"
                  id="dropdown-basic"
                  className="w-100"
                  style={{ backgroundColor: 'transparent', border: '1px solid lightgrey', color: 'black',textAlign:"left" }}
                >
                  {selectedRoleName}
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-100">
                  <Dropdown.Item
                    onClick={() => setSelectedRole("")}
                    disabled={true}
                    className="w-100"
                  >
                    Select a role
                  </Dropdown.Item>
                  {roles
                      .filter(role => role.name === 'User') // Filter to show only the 'User' role
                      .map(role => (
                        <Dropdown.Item
                          key={role.id}
                          onClick={() => setSelectedRole(role.id)}
                          className="w-100"
                        >
                          {role.name}
                        </Dropdown.Item>
                      ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </Col>
        </Row>
        {/* <Row>
          <Col md={6}>
          <Form.Group controlId="formStatus">
                <Form.Label>Status</Form.Label>
                <Dropdown onSelect={handleSelect}>
                  <Dropdown.Toggle variant="dark" id="dropdown-status" 
                  style={{ backgroundColor: 'transparent', border: '1px solid lightgrey', color: 'black' }}
                  >
                    {status}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="Active">Active</Dropdown.Item>
                    <Dropdown.Item eventKey="Inactive">Inactive</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
          </Col>

        </Row> */}
        <Button
          variant="dark"
          onClick={handleBackSubmit}
          type="button" // Change to button to avoid submitting the form
          className="user-register-back-btn"
          size="sm"
          style={{ backgroundColor: '#243847', borderColor: '#243847',margin: '15px 25px' }}
          >
          Back
        </Button>
        <Button
          variant="dark"
          type="submit"
          className="user-register-create-btn"
          size="sm"
          style={{ backgroundColor: '#243847', borderColor: '#243847' }}
        >
          Update
        </Button>
      </Form>
    </Container>
  </div>
);
};

export default EditUser;
