import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { API } from "../api/API";
import "../css/custom.css";
import { alertSuccessLogin, alertWarning } from "../alert";
import Layout from "../layouts/Layout";
import Upload from "../bom-upload/Upload";

import LabelPrintIndex from "../label-print/LabelPrintIndex";
import LabelPrint from "../label-print/LabelPrint";
import FGSFGTemplate from "../label-templates/FGSFGTemplate";
import FGSFGTemplate1 from "../label-templates/FGSFGTemplate1";
import BOCTemplate from "../label-templates/BOCTemplate";

import LabelReprint from "../label-reprint/LabelRePrint";
import LabelReprintIndex from "../label-reprint/LabelRePrintIndex";
import PickslipPrint from "../label-print/PickslipPrint";

import LoadingSlipPrintIndex from "../loadingslip-print/LoadingSlipPrintIndex";
import LoadingSlipPrint from "../loadingslip-print/LoadingSlipPrint";

import Report from "../reports/Report";
import ReprintReport from "../reports/ReprintReport";
import DetailedReport from "../reports/DetailedReport";
import PartNumberReport from "../reports/PartNumberReport";
import PackNumberReport from "../reports/PackNumberReport";
import LoadingSlipNumberReport from "../reports/LoadingSlipNumberReport";

import Dashboard from "../layouts/Dashboard";
import RoleMaster from "../masters/RoleMaster";
import NoAccessPage from "./NoAccessPage";
import RolePage from "../masters/RolePage";
import UserPage from "../masters/UserPage";
import ForgotPassUserPage from "../masters/ForgotPassUserPage";
import UserRegisterPage from "../masters/UserRegisterPage";
import ProtectPage from "./ProtectPage";
import ErrorPage from "./ErrorPage";
import EditRoleMaster from "../masters/EditRoleMaster";
import EditUserPage from "../masters/EditUserPage";
import axios from "axios";
import BOMInactive from "../bom-upload/BomInactive";
import LabelPrintInactive from "../bom-upload/LabelPrintInactive";

const RoutesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const api = new API();
  const excludedPaths = [
    "/fg-sfg-print",
    "/fg-sfg-print1",
    "/pickslip-print",
    "/boc-print",
    "/loading-slip-print",
  ];

  // useEffect(() => {
  //     const checkAuth = async () => {
  //         const response = await api.check_auth(navigate);
  //         console.log(response);
  //     };

  //     checkAuth();
  // }, [navigate]);

  // useEffect(() => {
  //     const handleTabClose = async(event) => {
  //         // event.preventDefault();
  //         // Logic to logout or invalidate session
  //         console.log("you've been logout");
  //         const token = localStorage.getItem('token');

  //         try {
  //             const response = await api.user_auth_logout(token);

  //             if (response.status === 200) {
  //                 // Clear token and show a success message
  //                 const message = response.data.message || 'Logged out successfully';
  //                 localStorage.removeItem('token');
  //                 localStorage.removeItem('userDetails');
  //                 alertSuccessLogin(message, navigate, "/login");
  //                 navigate('/login')
  //             }
  //         } catch (error) {
  //             if (error.response && error.response.status === 401) {
  //                 // Handle unauthenticated state
  //                 alertWarning(error.response.data.error || 'An error occurred while logging out');
  //             } else {
  //                 // Handle other errors
  //                 alertWarning('An error occurred while logging out');
  //             }
  //         }
  //         localStorage.removeItem('userDetails'); // Clear user details from localStorage
  //         // Optionally, you could make an API call to log out the user on the backend

  //     };

  //     window.addEventListener('beforeunload', handleTabClose);

  //     return () => {
  //         window.removeEventListener('beforeunload', handleTabClose);
  //     };
  // }, []);

  const [permissions, setPermissions] = useState({});
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    async function fetchRoleData() {
      try {
        const userDetailsString = localStorage.getItem("userDetails");
        if (!userDetailsString) {
          setLoading(false);
          return;
        }

        const userDetails = JSON.parse(userDetailsString);
        setUserDetails(userDetails);

        const user = await api.get_user_roles_by_id(userDetails.id);
        const response = await api.get_role_permissions(user.role[1]);
        if (response) {
          const permissionsState = {};
          Object.keys(response.modules).forEach((module) => {
            permissionsState[module] = true;
          });
          setPermissions(permissionsState);
          localStorage.setItem("permissions", JSON.stringify(permissionsState));
        }
      } catch (error) {
        console.error("Error fetching role permissions:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    }

    fetchRoleData();
  }, []);

  if (loading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="routes">
      {!excludedPaths.includes(location.pathname) && (
        <div className="">
          <Layout />
        </div>
      )}
      <Routes>
        <Route
          path="/register"
          element={<ProtectPage Child={UserRegisterPage} />}
        />

        <Route
          path="/user-role"
          element={<ProtectPage Child={RoleMaster} adminOnly={true} />}
        />
        <Route
          path="/edit-user-role/:userid"
          element={<ProtectPage Child={EditUserPage} />}
        />

        <Route
          path="/edit-role/:role"
          element={<ProtectPage Child={EditRoleMaster} adminOnly={true} />}
        />

        <Route
          path="/forgot-password/:userid"
          element={<ProtectPage Child={ForgotPassUserPage} adminOnly={true} />}
        />

        <Route path="/roles" element={<ProtectPage Child={RolePage} />} />
        <Route path="/users" element={<ProtectPage Child={UserPage} />} />

        <Route path="/*" element={<ErrorPage />} />
        <Route path="/no-access" element={<NoAccessPage />} />

        <Route path="/" element={<Dashboard />} />

        {/* <Route path="/bom-upload" element={<Upload/>}/> */}
        <Route
          path="/bom-upload"
          element={
            <ProtectPage Child={Upload} requiredPermissions={["bom_upload"]} />
          }
        />

        <Route
          path="/bom-inactive"
          element={
            <ProtectPage Child={BOMInactive} requiredPermissions={["bom_upload", "label_print"]} adminOnly={true} />
          }
        />

        <Route
          path="/label-print-inactive"
          element={
            <ProtectPage Child={LabelPrintInactive} requiredPermissions={["bom_upload", "label_print"]} adminOnly={true} />
          }
        />

        <Route path="/label-print-index" element={
          <ProtectPage
            Child={LabelPrintIndex}
            requiredPermissions={["label_print"]}
          />
        }
        />

        <Route
          path="/label-print"
          element={
            <ProtectPage
              Child={LabelPrint}
              requiredPermissions={["label_print"]}
            />
          }
        />
        <Route
          path="/fg-sfg-print"
          element={
            <ProtectPage
              Child={FGSFGTemplate}
              requiredPermissions={["label_print", "label_reprint"]}
            />
          }
        />

        <Route
          path="/fg-sfg-print1"
          element={
            <ProtectPage
              Child={FGSFGTemplate1}
              requiredPermissions={["label_print", "label_reprint"]}
            />
          }
        />

        <Route
          path="/boc-print"
          element={
            <ProtectPage
              Child={BOCTemplate}
              requiredPermissions={["label_print", "label_reprint"]}
            />
          }
        />
        <Route
          path="/label-reprint-index"
          element={
            <ProtectPage
              Child={LabelReprintIndex}
              requiredPermissions={["label_reprint"]}
            />
          }
        />
        <Route
          path="/label-reprint"
          element={
            <ProtectPage
              Child={LabelReprint}
              requiredPermissions={["label_reprint"]}
            />
          }
        />
        <Route
          path="/pickslip-print"
          element={
            <ProtectPage
              Child={PickslipPrint}
              requiredPermissions={["label_print", "label_reprint"]}
            />
          }
        />
        <Route
          path="/loading-slip-print-index"
          element={
            <ProtectPage
              Child={LoadingSlipPrintIndex}
              requiredPermissions={["loading_slip_print"]}
            />
          }
        />
        <Route
          path="/loading-slip-print"
          element={
            <ProtectPage
              Child={LoadingSlipPrint}
              requiredPermissions={["loading_slip_print"]}
            />
          }
        />

        <Route
          path="/reports"
          element={
            <ProtectPage Child={Report} requiredPermissions={["reports"]} />
          }
        />
        <Route
          path="/detailed-report"
          element={
            <ProtectPage
              Child={DetailedReport}
              requiredPermissions={["reports"]}
            />
          }
        />
        <Route
          path="/part-number-report"
          element={
            <ProtectPage
              Child={PartNumberReport}
              requiredPermissions={["reports"]}
            />
          }
        />

        <Route
          path="/pack-number-report"
          element={
            <ProtectPage
              Child={PackNumberReport}
              requiredPermissions={["reports"]}
            />
          }
        />

        <Route
          path="/loadingslip-number-report"
          element={
            <ProtectPage
              Child={LoadingSlipNumberReport}
              requiredPermissions={["reports"]}
            />
          }
        />

        <Route
          path="/reprint-reports"
          element={
            <ProtectPage
              Child={ReprintReport}
              requiredPermissions={["reports"]}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default RoutesPage;
