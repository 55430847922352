import Table from "react-bootstrap/esm/Table";
import Container from "react-bootstrap/esm/Container";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { API } from "../api/API";
import Logo from "../images/logo2.png";

export default function PickslipPrint() {
  const location = useLocation();
  const propDataString = localStorage.getItem('pickslipData');
  const propData = propDataString ? JSON.parse(propDataString) : null;
  const table_data = propData?.table_data;
  const api = new API();
  const [data, setData] = useState({
    pickslip_number: "",
    sales_order_number: "",
  });



  useEffect(() => {
    if (propData.production_order_header_id) {
      async function get() {
        const response = await api.pickslip_header_get(
          propData.production_order_header_id
        );
        setData(response);
      }

      get();

    }

  }, [propData.production_order_header_id]);

  useEffect(() => {
    if (data.pickslip_number !== "" && data.sales_order_number !== "") {
      window.print();
      window.close();
    }
  }, [data]);

  return (
    <div className="pickslipprint">
      <div className="packing-list-container">
        <div className="header">
          <div className="logo">
            <img
              src={Logo}
              alt="Logo"
              style={{
                width: '158px', height: '28px',
                position: 'relative'
              }}
            />
          </div>
          <div className="company-details">
            <h1>LUFTEK ENGINEERING PVT LTD</h1>
            <p>Luftek Engineering Pvt. Ltd., Plot No. A-11, Industrial Park - Ph II, Cheyyar-Kancheepuram Road, Kunnavakkam, Cheyyar SIPCOT, Tamil Nadu - 631701.</p>
          </div>
        </div>
        <br />
        <h2 style={{ textAlign: 'center', fontSize: '24px', margin: '10px' }}>Pickslip Print</h2>
        <br />
        <table className="packing-list-table">
          <tbody>
            <tr>
              <th>Sales Order Number</th>
              <td>{data.sales_order_number}</td>
              <th>Production Order Number</th>
              <td>{propData.production_order_number}</td>
            </tr>
            <tr >
              <th>Job Transaction Number</th>
              <td>{propData.job_transaction_number}</td>
              <th>Pickslip Number</th>
              <td>{data.pickslip_number}</td>
            </tr>
          </tbody>
        </table>

        <table className="item-list-table">
          <thead>
            <tr>
              <th>Item Number</th>
              <th>Item Description</th>
              <th>Item Quantity</th>
              <th>UOM</th>
            </tr>
          </thead>
          <tbody>
            {table_data?.map((row, index) => (
              <tr key={index}>
                <td>{row.item_number}</td>
                <td>{row.item_description}</td>
                <td>{row.item_qty}</td>
                <td>{row.uom}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
    </div>
  );
}
