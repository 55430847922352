import React, { useEffect, useState } from 'react'
import {Navigate, Link, useNavigate } from 'react-router-dom'
import { alertSuccess, alertSuccessLogin, alertWarning} from "../alert";
import Logo from "../images/logo2.png";

import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { API } from '../api/API';


function Loginpage() {

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [token, setToken] = useState(null);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const api = new API();
  const navigate = useNavigate("")

 useEffect(()=>{
  const x = localStorage.getItem("userDetails");

  if (x) {
    return navigate('/');
  }
 })

  //   const {loginUser} = useContext(AuthContext)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const data = { username, password };
        
        const response = await api.user_auth_login(data);
        
        const { message, user, access } = response.data;
        
        if (message === 'You are logged in somewhere else. Please log out from other devices.') {
            alertWarning(message);
        } else {
            console.log(response.data);
            
            // Store JWT token, user details, and session key separately in localStorage
            setToken(access);
            localStorage.setItem('token', access); // Store JWT token
            localStorage.setItem("userDetails", JSON.stringify(user)); // Store user details

            alertSuccessLogin(message, navigate, "/");
        }
    } catch (error) {
        if (error.response && error.response.data) {
            alertWarning(error.response.data.message || 'An error occurred');
        } else {
            alertWarning('An error occurred');
        }
    }
};



  return (
    <div>
      <>
        <section className="vh-100" >
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col col-xl-10" style={{ width: "42.33%" }}>
                <div className="card" style={{ borderRadius: "1rem" }}>
                  <div className="row g-0">
                    
                    <div className="col-md-6 col-lg-7 d-flex align-items-center" style={{ width: "100%" }}>
                      <div className="card-body p-4 p-lg-5 text-black">
                        <form onSubmit={handleSubmit}>
                          <div className="d-flex align-items-center mb-3 pb-1">
                            <i
                              className="fas fa-cubes fa-2x me-3"
                              style={{ color: "#ff6219" }}
                            />
                            <div className="d-flex align-items-center mb-3 pb-1">
                              <i
                                className="fas fa-cubes fa-2x me-3"
                                style={{ color: "#ff6219" }}
                              />
                              {/* <span className="h2 fw-bold mb-0">Welcome back 👋</span> */}
                              <img className='loginpage-lufteklogo' src={Logo} alt="" style={{ }} />
                            </div>
                          </div>
                          
                          <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="form2Example17">
                              Username
                            </label>
                            <input
                              type="text"
                              id="form2Example17"
                              className="form-control form-control-lg"
                              name='username'
                              value={username}
                              onChange={e => setUsername(e.target.value)}
                              required
                            />
                            
                          </div>
                          <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="form2Example27">
                              Password
                            </label>
                            <input
        type={isPasswordVisible ? 'text' : 'password'}
        id="form2Example27"
        className="form-control form-control-lg"
        name='password'
        value={password}
        onChange={e => setPassword(e.target.value)}
        required
      />
      <button
        type="button"
        className="password-toggle-button"
        onClick={togglePasswordVisibility}
      >
        {isPasswordVisible ? <FaEyeSlash /> : <FaEye />} {/* Toggle icon */}
      </button>
                          </div>
                          <div className="pt-1 mb-4">
                            <button
                              className="btn btn-dark btn-lg btn-block loginpage-loginbutton"
                              type="submit"
                            >
                              Login
                            </button>
                          </div>
                          {/* <a className="small text-muted" href="#!">
                            Forgot password?
                          </a>
                          <p className="mb-5 pb-lg-2" style={{ color: "#393f81" }}>
                            Don't have an account?{" "}
                            <Link to="/register" style={{ color: "#393f81" }}>
                              Register Now
                            </Link>
                          </p> */}
                          
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
      </>

    </div>
  )
}

export default Loginpage