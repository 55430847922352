import Table from "react-bootstrap/esm/Table";
import Container from "react-bootstrap/esm/Container";
import '../css/custom.css'
import Logo from "../images/logo2.png";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function LoadingSlipPrint() {

  const loadingslipDataString = localStorage.getItem('loadingSlipData');
  const loadingSlipData = loadingslipDataString ? JSON.parse(loadingslipDataString) : null;

  const header_data = loadingSlipData.header_data[0];
  const content_data = loadingSlipData.content_data;
  const footer_data = loadingSlipData.footer_data;
  

  useEffect(() => {
    // Check if print preview has already been shown
    const hasPrinted = sessionStorage.getItem('hasPrinted');
    
    if (!hasPrinted) {
        window.print();
        sessionStorage.setItem('hasPrinted', true);
    }

    // Automatically close and refresh page after print dialog
    const timer = setTimeout(() => {
        window.close();
        window.location.reload();
    }, 100); // Adjust the delay if needed

    return () => clearTimeout(timer);
}, []);


  return (
    <div className="loadingslipprint">
      <div className="packing-list-container">
        <div className="header">
          <div className="logo">
            <img
              src={Logo}
              alt="Logo"
              style={{
                width: '158px', height: '28px',
                position: 'relative'
              }}
            />
          </div>
          <div className="company-details">
            <h1>LUFTEK ENGINEERING PVT LTD</h1>
            <p>Luftek Engineering Pvt. Ltd., Plot No. A-11, Industrial Park - Ph II, Cheyyar-Kancheepuram Road, Kunnavakkam, Cheyyar SIPCOT, Tamil Nadu - 631701.</p>
          </div>
        </div>

        <h2 style={{ textAlign: 'center', fontSize: '24px',margin:'5px' }}>Packing List</h2>

        <table className="packing-list-table">
          <tbody>
            <tr >
              <th>CLIENT</th>
              <td colSpan='3'>{header_data.client}</td>
              <th>DATE</th>
              <td>20-08-2024</td>
            </tr>
            <tr >
              <th rowSpan='2'>PROJECT</th>
              <td colSpan='3' rowSpan='2'>{header_data.project}</td>
              <th>VEHICLE No.</th>
              <td>{header_data.vehicle_number}</td>
            </tr>
            <tr >
              <th>JTN No.</th>
              <td>{header_data.job_transaction_number}</td>
            </tr>
            <tr >
              <th>REFERENCE</th>
              <td colSpan='3'>{header_data.reference}</td>
              <th>UNIT QTY.</th>
              <td>1</td>
            </tr>
            <tr>
              <th>SALES ORDER -NO.</th>
              <td colSpan='3'>{header_data.sales_order_number}</td>
              <th>INVOICE No.</th>
              <td></td>
            </tr>
            <tr>
              <th>CONDITION</th>
              <td>CKD <input type="checkbox" /></td>
              <td>SKD <input type="checkbox" /></td>
              <td>ASS <input type="checkbox" /></td>
              <th>DISPATCH</th>
              <td colSpan="3">FULL / PART</td>
            </tr>

          </tbody>
        </table>




        <table className="item-list-table">
          <thead>
            <tr>
              <th>SL. No.</th>
              <th>Packet Number/Box Number</th>
              <th>Item Description</th>
              <th>Verified By</th>
            </tr>
          </thead>
          <tbody>
            {content_data.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.pack_box_number}</td>
                <td>{item.part_description}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
          <tr id="table-footer">
              <td colSpan="3">Total No. of Packets</td>
              <td>{footer_data}</td>
            </tr>
        </table>

        <div className="footer">
          <p>REMARKS - (PENDING FROM FACTORY):-  </p>
          <p>Note: Please count the No. of packets and sign a copy of this packing list and send to factory for our records, if you find any discrepancy in the no. of packets.</p>
          <div className="signature-block">
            <div>Prepared By</div>
            <div>Checked By</div>
            <div>Receiver Name & Sign</div>
          </div>
        </div>
      </div>
    </div>
  );
}
