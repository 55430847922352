import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { API } from "../api/API";
import DataTable from "react-data-table-component";
import Container from "react-bootstrap/esm/Container";
import { faL } from "@fortawesome/free-solid-svg-icons";

const BOMInactive = () => {
  const api = new API();

  const [productionOrders, setProductionOrders] = useState([]);
  // State for search terms
  const [filters, setFilters] = useState({
    sno: "",
    productionOrderNumber: "",
    jobTransactionNumber: "",
    productionOrderQty: "",
    createdAt: "",
    createdBy: "",
    status: "",
  });

  // Handle filter input changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Filter data based on filters
  const filteredData = productionOrders.filter(
    (row) =>
      row.production_order_number
        .toLowerCase()
        .includes(filters.productionOrderNumber.toLowerCase()) &&
      row.job_transaction_number
        .toLowerCase()
        .includes(filters.jobTransactionNumber.toLowerCase()) &&
      row.production_order_qty
        .toString()
        .includes(filters.productionOrderQty) &&
      row.created_at.toString().includes(filters.createdAt) &&
      row.created_by.toString().includes(filters.createdBy) &&
      row.status.toLowerCase().includes(filters.status.toLowerCase())
  );

  useEffect(() => {
    async function get() {
      const data = await api.production_header_get();
      setProductionOrders(data);
    }

    get();
  }, []);

  const columns = [
    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>SNO</span>
            <input
              name="sno"
              type="text"
              placeholder="SNO"
              value={filters.sno}
              onChange={handleFilterChange}
              // disabled
              style={{
                marginTop: "3px",
                marginBottom: "2px",
                width: "100%",
                height: "20px",
                padding: "5px",
                textAlign: "center"
              }}
            />
          </div>
        </>
      ),
      selector: (row, index) => (filteredData.length ? index + 1 : ""),
      sortable: false,
      width: "80px",
    },
    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Production Order Number</span>
            <input
              type="text"
              name="productionOrderNumber"
              value={filters.productionOrderNumber}
              onChange={handleFilterChange}
              placeholder="Production Order Number"
              style={{
                marginTop: "3px",
                marginBottom: "2px",
                width: "100%",
                height: "20px",
                padding: "5px",
                textAlign: "center"
              }}
            />
          </div>
        </>
      ),
      selector: (row) => row.production_order_number,
      sortable: false,
      width: '220px'
    },
    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Job Transaction Number</span>

            <input
              type="text"
              name="jobTransactionNumber"
              value={filters.jobTransactionNumber}
              onChange={handleFilterChange}
              placeholder="Job Transaction Number"
              style={{
                marginTop: "3px",
                marginBottom: "2px",
                width: "100%",
                height: "20px",
                padding: "5px",
                textAlign: "center"
              }}
            />
          </div>
        </>
      ),
      selector: (row) => (
        <NavLink
          to="/label-print-inactive"
          state={{
            productionOrderID: row.id,
            productionOrderNumber: row.production_order_number,
            jobTransactionNumber: row.job_transaction_number,
            productionOrderQty: row.production_order_qty,
            status: row.status,
          }}
        >
          {row.job_transaction_number}
        </NavLink>
      ),
      sortable: false,
      width: '220px'
    },
    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Production Order Quantity</span>

            <input
              type="text"
              name="productionOrderQty"
              value={filters.productionOrderQty}
              onChange={handleFilterChange}
              placeholder="Production Order Quantity"
              style={{
                marginTop: "3px",
                marginBottom: "2px",
                width: "215px",
                height: "20px",
                padding: "5px",
                textAlign: "center"
              }}
            />
          </div>
        </>
      ),
      selector: (row) => row.production_order_qty,
      sortable: false,
      width: '220px'
    },
    {
      name: (
        <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span>Created At</span>
          
          <input
            type="text"
            name="createdAt"
            value={filters.createdAt}
            onChange={handleFilterChange}
            placeholder="Created At"
            style={{marginTop: '3px',marginBottom:'2px',  width: '100%',height:"20px", padding: '5px', textAlign: "center" }}
          />
          </div>
        </>
      ),
      selector: row => row.created_at,
      // sortable: true,
      width: '180px'
    },
    {
      name: (
        <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span>Created By</span>
          
          <input
            type="text"
            name="createdBy"
            value={filters.createdBy}
            onChange={handleFilterChange}
            placeholder="Created By"
            style={{marginTop: '3px',marginBottom:'2px',  width: '100%',height:"20px", padding: '5px', textAlign: "center" }}
          />
          </div>
        </>
      ),
      selector: row => row.created_by,
      // sortable: true,
      width: '150px'
    },
    {
      name: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Status</span>

            <input
              type="text"
              name="status"
              value={filters.printStatus}
              onChange={handleFilterChange}
              placeholder="Status"
              style={{
                marginTop: "3px",
                marginBottom: "2px",
                width: "100%",
                height: "20px",
                padding: "5px",
                textAlign: "center"
              }}
            />
          </div>
        </>
      ),
      selector: (row) => row.status,
      sortable: false,
      width: "140px",
    },
  ];
  return (
    <div className="bominactive">
      <div
        className="basic"
        style={{ position: "relative", marginTop: "40px" }}
      >
        <Container
        // style={{ maxWidth: "968px" }}
        >
          {/* Data Table with filters inside headers */}
          <DataTable
            className="data-table-container"
            columns={columns}
            data={filteredData.length ? filteredData : [{}]}
            pagination
            responsive
            highlightOnHover
            dense
          />
        </Container>
      </div>
    </div>
  );
};

export default BOMInactive;
