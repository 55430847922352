import { useState, useEffect } from "react";
import { API } from "../api/API";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/esm/Button";
import DataTable from "react-data-table-component";

export default function LoadingSlipNumberReport() {
  const api = new API();
  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const loading_slip_number = location.state.loading_slip_number;


  useEffect(() => {
    async function get_loadingslip_number(){
        const response = await api.reports_get_loadingslip_number(loading_slip_number);
        setData(response);
    }

    get_loadingslip_number();

  }, [loading_slip_number])

// State for search terms
const [filters, setFilters] = useState({
  loadingSlipNumber: '',
  packNumber: '',
  
});

// Handle filter input changes
const handleFilterChange = (e) => {
  const { name, value } = e.target;
  setFilters(prevFilters => ({
    ...prevFilters,
    [name]: value
  }));
};

// Filter data based on filters
const filteredData = data.filter(row =>
  row.pack_number.toString().toLowerCase().includes(filters.packNumber.toString().toLowerCase())
   && row.loading_slip_number.toString().toLowerCase().includes(filters.loadingSlipNumber.toString().toLowerCase())     
);
  const columns = [
    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span>SNO</span>
            <input
              type="text"
              name="assemblyStatus"
              // value={filters.assemblyStatus}
              // onChange={handleFilterChange}
              placeholder="SNO"
              style={{ marginTop: '3px',marginBottom:'2px', width: '100%', height: "25px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row, index) => filteredData.length ? index + 1 : '',
      sortable: false,
      style: {
        textAlign: "center",
      },
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span>Loading Slip Number</span>
            <input
              type="text"
              name="loadingSlipNumber"
              value={filters.loadingSlipNumber}
              onChange={handleFilterChange}
              placeholder="Loading Slip Number"
              style={{ marginTop: '3px', width: '100%', height: "25px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.loading_slip_number,
      sortable: false,
      style: {
        textAlign: "center",
      },
    },

    {
      name: (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span>Pack Number</span>
            <input
              type="text"
              name="packNumber"
              value={filters.packNumber}
              onChange={handleFilterChange}
              placeholder="Pack Number"
              style={{ marginTop: '3px', width: '100%', height: "25px", padding: '5px' }}
            />
          </div>
        </>
      ),
      selector: (row) => row.pack_number,
      sortable: false,
      style: {
        textAlign: "center",
      },
    },
  ];

  const handleBackSubmit = () => {
    navigate(-1);
  };

  return (
    <>
      <div
        className="basic"
        style={{ position: "relative", marginTop: "30px" }}
      >
        {" "}
      </div>

      <Container>
        <DataTable
          className="data-table-container"
          columns={columns}
          data={filteredData.length ? filteredData : [{}]}
          pagination
          responsive
          highlightOnHover
          dense
        />

        <Button
          variant="dark"
          onClick={handleBackSubmit}
          type="button" // Change to button to avoid submitting the form
          className="user-register-back-btn"
          size="sm"
          style={{
            backgroundColor: "#243847",
            borderColor: "#243847",
            position: "relative",
            top: "0px",
          }}
        >
          Back
        </Button>
      </Container>
    </>
  );
}
