import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { API } from "../api/API";
import DataTable from 'react-data-table-component'; // Import DataTable
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function LoadingSlipPrintIndex() {
    const api = new API();
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const [filters, setFilters] = useState({
        salesOrderNumber: '',
        jobTransactionNumber: '',
        loadingSlipNumber: '',
        createdAt: "",
        createdBy: "",
    });

    // Handle filter input changes
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value
        }));
    };

    useEffect(() => {
        async function get() {
            try {
                const result = await api.loading_slip_header_get();
                setData(result);
                
            } catch (error) {
                console.error('Error fetching loading slips:', error);
            }
        }

        get();
    }, []);

    const handlePrint = async (loadingSlipID) => {
        const response = await api.loading_slip_print_transaction_get(loadingSlipID);

        localStorage.removeItem('loadingSlipData');
        localStorage.setItem('loadingSlipData', JSON.stringify(response));

        window.open(`/loading-slip-print`, '_blank');

    }

    // Filter data based on filters
  const filteredData = data.filter(row =>
    row.sales_order_number.toLowerCase().includes(filters.salesOrderNumber.toLowerCase()) &&
    row.job_transaction_number.toLowerCase().includes(filters.jobTransactionNumber.toLowerCase()) &&
    row.loading_slip_number.toLowerCase().includes(filters.loadingSlipNumber.toLowerCase()) &&
    row.created_at.toString().includes(filters.createdAt)&&
    row.created_by.toString().includes(filters.createdBy)
  );

    const columns = [
        {
            name: (
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span>SNO</span>
                        <input
                            type="text"
                            placeholder="SNO"
                            // disabled
                            style={{ marginTop: '3px', marginBottom: '2px', width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
                        />
                    </div>
                </>
            ),
            selector: (row, index) => filteredData.length ? index + 1 : '',
            sortable: false,
            width: '80px', // Adjust width as needed
            style: {
                textAlign: 'center'
            }
        },

        {
            name: (
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span>Sales Order Number</span>
                        <input
                            type="text"
                            name="salesOrderNumber"
                            value={filters.salesOrderNumber}
                            onChange={handleFilterChange}
                            placeholder="Sales Order Number"
                            style={{ marginTop: '3px', marginBottom: '2px', width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
                        />
                    </div>
                </>
            ),
            selector: row => row.sales_order_number,
            sortable: false,
            style: {
                textAlign: 'center'
            },

        },

        {
            name: (
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span>Job Transaction Number</span>
                        <input
                            type="text"
                            name="jobTransactionNumber"
                            value={filters.jobTransactionNumber}
                            onChange={handleFilterChange}
                            placeholder="Job Transaction Number"
                            style={{ marginTop: '3px', marginBottom: '2px', width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
                        />
                    </div>
                </>
            ),
            selector: row => row.job_transaction_number,
            sortable: false,
            style: {
                textAlign: 'center'
            },

        },

        {
            name: (
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span>Loading Slip Number</span>
                        <input
                            type="text"
                            name="loadingSlipNumber"
                            value={filters.loadingSlipNumber}
                            onChange={handleFilterChange}
                            placeholder="Loading Slip Number"
                            style={{ marginTop: '3px', marginBottom: '2px', width: '100%', height: "20px", padding: '5px', textAlign: "center" }}
                        />
                    </div>
                </>
            ),
            selector: row => row.loading_slip_number,
            sortable: false,
            style: {
                textAlign: 'center'
            },

        },
        {
            name: (
              <>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span>Created At</span>
                
                <input
                  type="text"
                  name="createdAt"
                  value={filters.createdAt}
                  onChange={handleFilterChange}
                  placeholder="Created At"
                  style={{marginTop: '3px',marginBottom:'2px',  width: '100%',height:"20px", padding: '5px', textAlign: "center" }}
                />
                </div>
              </>
            ),
            selector: row => row.created_at,
            // sortable: true,
            width: '180px'
          },
          {
            name: (
              <>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <span>Created By</span>
                
                <input
                  type="text"
                  name="createdBy"
                  value={filters.createdBy}
                  onChange={handleFilterChange}
                  placeholder="Created By"
                  style={{marginTop: '3px',marginBottom:'2px',  width: '100%',height:"20px", padding: '5px', textAlign: "center" }}
                />
                </div>
              </>
            ),
            selector: row => row.created_by,
            // sortable: true,
            width: '150px'
          },
        {
            name: 'Print',
            cell: row => (
                filteredData.length ?
                <Button
                    className="btn btn-primary"
                    style={{ backgroundColor: '#243847', borderColor: '#243847', height: "25px", width: "45px", padding: "0", fontSize: "14px" }}
                    onClick={() => handlePrint(row.id)}
                >
                    Print
                </Button> : [""]
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '120px', // Adjust width as needed
            style: {
                textAlign: 'center'
            }
        }
    ];

    return (
        <div>
            <div className='basic' style={{ position: "relative", marginTop: "40px" }}>
                <Container
                    style={{ maxWidth: "max-content" }}
                >
                    <DataTable className="data-table-container"
                        columns={columns}
                        data={filteredData.length ? filteredData : [{}]}
                        pagination
                        responsive
                        highlightOnHover
                        dense
                    />
                </Container>
            </div>
        </div>
    );
}
