import React, { useState, useEffect } from "react";
import {
  AppBar,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Button
} from "@mui/material";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../images/logo2.png";
import Userl from "../images/user2.png";
import { ThemeProvider } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import Sidebar from "./Sidebar";
import axios from "axios";
import { alertSuccess, alertSuccessLogin, alertWarning } from "../alert";
import { API } from "../api/API";

const Layout = () => {
  const [value, setValue] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false); // State to check if user is admin
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const navigate = useNavigate();
  const api = new API();

  const [role, setRole] = useState('');
  const [permissions, setPermissions] = useState({
    bom_upload: false,
    label_print: false,
    label_reprint: false,
    assembly: false,
    fg: false,
    load_process: false,
    loading_slip_print: false,
    reports: false,
  });

  useEffect(() => {
    async function fetchRoleData() {
      try {
        const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        const user = await api.get_user_roles_by_id(userDetails.id);
        const response = await api.get_role_permissions(user.role[1]);
        if (response) {
          setRole(user.role);
          // Transform permissions to match the state structure
          const permissionsState = {};
          Object.keys(response.modules).forEach(module => {
            permissionsState[module] = true; // Assuming presence means true
          });
          setPermissions(permissionsState);
        }
      } catch (error) {
        console.error("Error fetching role permissions:", error);
      }
    }

    fetchRoleData();
  }, [setRole]); // Dependency on role parameter


  // Update the tab value based on the current route
  useEffect(() => {
    switch (location.pathname) {
      case '/bom-upload':
        setValue(0);
        break;
      case '/bom-inactive':
        setValue(0);
        break;
      case '/label-print-index':
        setValue(1);
        break;
      case '/label-print':
        setValue(1);
        break;
      case '/label-reprint-index':
        setValue(2);
        break;
      case '/label-reprint':
        setValue(2);
        break;
      case '/loading-slip-print-index':
        setValue(3);
        break;
      case '/reports':
        setValue(4);
        break;
      case '/detailed-report':
        setValue(4);
        break;
      case '/part-number-report':
        setValue(4);
        break;
      case '/pack-number-report':
        setValue(4);
        break;
      case '/loadingslip-number-report':
        setValue(4);
        break;
      case '/reprint-reports':
        setValue(4);
        break;
      case '/users':
        setValue(5);
        break;
      case '/users/':
        setValue(5);
        break;
      case '/register':
        setValue(5);
        break;
      default:
        setValue(false); // Adjust this to default value if needed
        break;
    }
  }, [location.pathname]);

  // Check if the user is an admin
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails && userDetails.username === 'admin1') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [anchorE3, setAnchorE3] = useState(null);
  const [anchor, setAnchor] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfClick = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleBOMConfClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleReportConfClick = (event) => {
    setAnchorE3(event.currentTarget);
  };

  const handleConfClose = () => {
    setAnchor(null);
    setAnchorE3(null);
    setAnchorE2(null);
  };

  const handleUsersClick = () => {
    handleConfClose()
    navigate(`/users/`);
  };

  const handleRolesClick = () => {
    handleConfClose()
    navigate(`/roles/`);
  };
  const open2 = Boolean(anchorE2);
  const open4 = Boolean(anchorE3);
  const open3 = Boolean(anchor);

  const open = Boolean(anchorEl);
  const user2 = JSON.parse(localStorage.getItem("userDetails"));


  const handleLogout = async () => {
    const token = localStorage.getItem('token');

    try {
      // const response = await api.user_auth_logout(token);
      const response = await api.user_auth_logout(user2.id);

      if (response.status === 200) {
        // Clear token and show a success message
        const message = response.data.message || 'Logged out successfully';
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        alertSuccessLogin(message, navigate, "/login");
        navigate('/login')
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle unauthenticated state
        alertWarning(error.response.data.error || 'An error occurred while logging out');
      } else {
        // Handle other errors
        alertWarning('An error occurred while logging out');
      }
    }
  };


  return (
    // <ThemeProvider theme={theme}>
    <AppBar position="static" sx={{
      background: '#243847', backgroundColor: '#243847', width: '100%', display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between', alignItems: 'center'
      //  padding: '0 46px'
    }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        {/* Left Section */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: '#FFFFFF',
            width: '230px',
            height: '63.99px',
            position: 'relative',
            right: '24px',
            // padding: '0 46px'
          }}
        >
          <NavLink to="/" style={{ textDecoration: 'none' }}>
            <img
              src={Logo}
              alt="Logo"
              style={{
                width: '158px', height: '28px',
                position: 'relative', left: '33px'
              }}
            />
          </NavLink>
        </Box>

        {/* Right Section */}
        <Box>
          {isMatch ? (
            <Sidebar />
          ) : (
            <Tabs value={value} onChange={(e, newValue) => setValue(newValue)} TabIndicatorProps={{
              style: { backgroundColor: 'white' },
            }}>
              {(permissions.bom_upload) && (
                <>
                  <Button
                    onClick={handleBOMConfClick}
                    color="inherit"
                    style={{ textTransform: 'none', padding: '0 16px' }}
                  >
                    BOM
                  </Button>
                  <Menu
                    anchorEl={anchor}
                    open={Boolean(anchor)}
                    onClose={handleConfClose}
                    PaperProps={{
                      style: {
                        padding: 0,
                        marginTop: '0px',
                        transform: 'translateX(-30px)',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                      },
                    }}
                  >
                    <Link to="/bom-upload" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <MenuItem
                        style={{
                          fontSize: '0.95rem',
                          padding: '3px 40px',
                          display: 'flex',
                          justifyContent: 'center',
                          borderBottom: '1px solid #e0e0e0', // Adds a subtle divider between items
                          width: '100%',
                        }}
                        onClick={handleConfClose}
                      >
                        Upload
                      </MenuItem>
                    </Link>
                    {isAdmin && (

                      <>
                        <Link to="/bom-inactive" style={{ textDecoration: 'none', color: 'inherit' }}>
                          <MenuItem
                            style={{
                              fontSize: '0.95rem',
                              padding: '3px 40px',
                              display: 'flex',
                              justifyContent: 'center',
                              width: '100%',
                            }}
                            onClick={handleConfClose}
                          >
                            Inactive
                          </MenuItem>
                        </Link>
                      </>
                    )}
                  </Menu>
                </>
                // <Tab
                //   key='bom-upload'
                //   label="BOM Upload"
                //   component={NavLink}
                //   to="/bom-upload"
                //   sx={{
                //     textDecoration: "none",
                //     color: "inherit",
                //     textTransform: "none",
                //     "&.active": { color: "inherit" }
                //   }}
                // />
              )}

              {(permissions.label_print) && (
                <Tab
                style={{color: "white"}}
                  key='label-print'
                  label="Label Print"
                  component={NavLink}
                  to="/label-print-index"
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                    textTransform: "none",
                    "&.active": { color: "inherit" }
                  }}
                />
              )}

              {(permissions.label_reprint) && (
                <Tab
                style={{color: "white"}}
                  key='label-reprint'
                  label="Label Reprint"
                  component={NavLink}
                  to="/label-reprint-index"
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                    textTransform: "none",
                    "&.active": { color: "inherit" }
                  }}
                />
              )}

              {(permissions.loading_slip_print) && (
                <Tab
                  style={{color: "white"}}
                  key='loadingslip'
                  label="Loading Slip Print"
                  component={NavLink}
                  to="/loading-slip-print-index"
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                    textTransform: "none",
                    "&.active": { color: "inherit" }
                  }}
                />
              )}

              {(permissions.reports) && (
                <>
                  <Button
                    onClick={handleReportConfClick}
                    color="inherit"
                    style={{ textTransform: 'none', padding: '0 16px' }}
                  >
                    Reports
                  </Button>
                  <Menu
                    anchorEl={anchorE3}
                    open={open4}
                    onClose={handleConfClose}
                    PaperProps={{
                      style: {
                        padding: 0,
                        marginTop: '0px',
                        transform: 'translateX(-30px)',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                      },
                    }}
                  >
                    <Link to="/reports" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <MenuItem
                        style={{
                          fontSize: '0.95rem',
                          padding: '3px 40px',
                          display: 'flex',
                          justifyContent: 'center',
                          borderBottom: '1px solid #e0e0e0',
                          width: '100%',
                        }}
                        onClick={handleConfClose}
                      >
                        Print
                      </MenuItem>
                    </Link>
                    <Link to="/reprint-reports" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <MenuItem
                        style={{
                          fontSize: '0.95rem',
                          padding: '3px 40px',
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                        onClick={handleConfClose}
                      >
                        Reprint
                      </MenuItem>
                    </Link>
                  </Menu>
                </>
                // <Tab
                //   key='reports'
                //   label="Reports"
                //   component={NavLink}
                //   to="/reports"
                //   sx={{
                //     textDecoration: "none",
                //     color: "inherit",
                //     textTransform: "none",
                //     "&.active": { color: "inherit" }
                //   }}
                // />
              )}

              {/* {permissions.assembly && (
        <Tab
          key='assembly'
          label="Assembly"
          component={NavLink}
          to="/assembly"
          sx={{
            textDecoration: "none",
            color: "inherit",
            textTransform: "none",
            "&.active": { color: "inherit" }
          }}
        />
      )} */}
              {/* {isAdmin && (

                <> */}
              <Button onClick={handleConfClick} color="inherit" style={{ textTransform: 'none', padding: '0 16px' }}>
                Configuration
              </Button>
              <Menu anchorEl={anchorE2} open={open2} onClose={handleConfClose}
                PaperProps={{
                  style: {
                    padding: 0,
                    marginTop: '0px',
                    transform: 'translateX(0px)',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
                  },
                }}
              >
                <MenuItem onClick={() => handleUsersClick()} style={{
                  fontSize: '0.95rem', paddingTop: 0,
                  width: '100%', display: 'flex', justifyContent: 'center', borderBottom: '1px solid #e0e0e0', padding: '3px 40px'
                }}>User</MenuItem>
                <MenuItem onClick={() => handleRolesClick()} style={{
                  fontSize: '0.95rem', paddingTop: 0,
                  width: '100%', display: 'flex', justifyContent: 'center', padding: '3px 40px'
                }}>Role</MenuItem>
              </Menu>
              {/* </>
              )} */}
              <Tab
                key="download-apk"
                label="Download APK"
                component="a"
                href="/android-apk/Android APK.apk"  // Replace with the actual file URL
                download  
                sx={{
                  textDecoration: "none",
                  color: "inherit !important",       
                  textTransform: "none",
                  "&:visited": { color: "inherit !important" },  
                }}
              />
            </Tabs>
          )}
        </Box>


      </Toolbar>
      <div className="icon" style={{ position: 'relative', right: '8%' }}>
        <IconButton onClick={handleClick} color="inherit">
          <img
            src={Userl}
            alt="Logo"
            style={{ width: '36px', height: '34px' }}
          />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: 128,
              height: 88,
            },
          }}
        >
          <MenuItem>
            {user2.username}
          </MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </AppBar>
    //  </ThemeProvider>
  );
};

export default Layout;
