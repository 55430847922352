import { useState, useEffect } from "react";

import Swal from "sweetalert2";
import Container from "react-bootstrap/esm/Container";
import Table from "react-bootstrap/esm/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";

import { useLocation, useNavigate } from "react-router-dom";

import { API } from "../api/API";

import "../css/custom.css";
import Layout from "../layouts/Layout";
import { alertWarning } from "../alert";

export default function LabelReprint() {
  const location = useLocation();
  const navigate = useNavigate();

  const api = new API();
  const productionOrderID = location.state?.productionOrderID;
  const productionOrderNumber = location.state?.productionOrderNumber;
  const jobTransactionNumber = location.state?.jobTransactionNumber;
  const productionOrderQty = location.state?.productionOrderQty;

  const [selectedLabel, setSelectedLabel] = useState("");

  const type = "checkbox";

  const [key, setKey] = useState("FG/SFG");
  const [selected, setSelected] = useState([]);
  const [printData, setPrintData] = useState([]);
  const [count, setCount] = useState(0);
  const [reason, setReason] = useState("");
  const [data2, setData2] = useState([]);

  const [data, setData] = useState({
    transaction_data_fg_sfg: [],
    transaction_data_boc: [],
    print_status: "",
    inward_status: "",
    status: "",
    label_print_status: "",
    boc_print_status: "",
    labels: [],
  });

  const handleLabelCountChange = (event) => {
    setCount(event.target.value);
  };

  const handleHeaderCheckBoxFGSFG = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelected([]);
      setPrintData([]);

      setSelected(data.transaction_data_fg_sfg.map((row) => row.item_number));
      setPrintData(data.transaction_data_fg_sfg);
    } else {
      setSelected([]);
      setPrintData([]);
    }
  };

  const handleCheckBoxSFGFG = (event, row) => {
    const isChecked = event.target.checked;
    const updateSelected = isChecked
      ? [...selected, row.item_number]
      : selected.filter((item) => item !== row.item_number);
    const updatePrintData = isChecked
      ? [...printData, row]
      : printData.filter((item) => item.item_number === row.item_number);

    setSelected(updateSelected);
    setPrintData(updatePrintData);
  };

  const handleSubmitSFGFG = (event) => {
    event.preventDefault();
    if (reason === "") {
      alertWarning("Please Enter the reason");
    } else if (selected.length === 0 || printData.length === 0) {
      alertWarning("Please select at least one checkbox to print.");
    } else if (selectedLabel.length === 0) {
      alertWarning("Please select any Label Template to print.");
    } else {
      api.label_reprint_transaction_create(
        printData,
        productionOrderNumber,
        productionOrderQty,
        jobTransactionNumber,
        reason,
        selectedLabel,
        navigate
      );
    }
  };

  const handleSubmitBOC = (event) => {
    event.preventDefault();
    if (reason === "") {
      alertWarning("Please enter the reason");
    } else if (selectedLabel.length === 0) {
      alertWarning("Please select any Label Template to print.");
    } else {
      api.boc_reprint_transaction_create(
        productionOrderNumber,
        productionOrderID,
        reason,
        selectedLabel,
        navigate
      );
    }
  };

  useEffect(() => {
    async function get() {
      const transaction = await api.production_transaction_get(
        productionOrderID
      );
      setData(transaction);
    }
    async function getboc() {
      const data = await api.boc_print_transaction_get(productionOrderID);
      setData2(data);
    }

    get();
    getboc();
  }, [productionOrderID, jobTransactionNumber, productionOrderQty]);


  const handleBackSubmit = () => {
    navigate("/label-reprint-index");
  };

  const handleChange = (event) => {
    setSelectedLabel(event.target.value);
  };

  const handleReason = (e) => {
    const value = e.target.value;

    const regex = /^[a-zA-Z0-9_]*$/;

    if (regex.test(value)) {
      setReason(value);
    } else {
      console.log("Invalid input, special characters are not allowed.");
    }
  };

  return (
    <>
      <div className="basic" style={{ position: "relative", marginTop: "1px" }}>
        <Container>
          <br />
          <span style={{ paddingRight: "30px" }}>
            <b> Production Order Number: {productionOrderNumber} </b>
          </span>
          <span style={{ paddingRight: "30px" }}>
            <b> Job Transaction Number: {jobTransactionNumber} </b>
          </span>
          <span style={{ paddingRight: "30px" }}>
            <b> Production Order Quantity: {productionOrderQty} </b>
          </span>
          <span style={{ paddingRight: "30px" }}>
            <b>
              {" "}
              Reason:{" "}
              <input
                type="text"
                id="my-input"
                value={reason}
                onChange={handleReason}
              />{" "}
            </b>
          </span>

          <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="custom-mb mb-3"
          >
            <Tab eventKey="FG/SFG" title="FG/SFG">
              {data.transaction_data_fg_sfg.length > 0 && (
                <Table responsive className="custom-table">
                  <thead>
                    <tr>
                      <th>
                        <Form.Check
                          className="custom-checkbox"
                          type={type}
                          checked={
                            selected.length ==
                            data.transaction_data_fg_sfg.length
                          }
                          onChange={handleHeaderCheckBoxFGSFG}
                        />
                      </th>
                      <th>Item Type</th>
                      <th>Item Number</th>
                      <th>Item Description</th>
                      <th>Item Level</th>
                      <th>Item Quantity</th>
                      <th>Item UOM</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data.transaction_data_fg_sfg.map((row) => (
                      <tr key={row.id}>
                        <td>
                          <Form.Check
                            type={type}
                            checked={selected.includes(row.item_number)}
                            onChange={(event) =>
                              handleCheckBoxSFGFG(event, row)
                            }
                          />
                        </td>
                        <td>{row.item_type}</td>
                        <td>{row.item_number}</td>
                        <td>{row.item_description}</td>
                        <td>{row.item_level}</td>
                        <td>{row.item_qty}</td>
                        <td>{row.uom}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}

              <Form.Select
                style={{ width: "200px", position: "absolute", right: "90px" }}
                value={selectedLabel}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select a Label
                </option>
                {data.labels
                  .filter((label) => label.label_type === "FG/SFG")
                  .map((label) => (
                    <option key={label.id} value={label.label_name}>
                      {label.label_name}
                    </option>
                  ))}
              </Form.Select>

              <Button
                variant="dark"
                onClick={handleBackSubmit}
                type="button" // Change to button to avoid submitting the form
                className="user-register-back-btn"
                size="sm"
                style={{
                  backgroundColor: "#243847",
                  borderColor: "#243847",
                  position: "relative",
                  top: "60px",
                }}
              >
                Back
              </Button>
              <div className="right-align">
                <Button
                  variant="dark"
                  type="submit"
                  className="custom-button"
                  size="sm"
                  onClick={handleSubmitSFGFG}
                  style={{ backgroundColor: "#243847", borderColor: "#243847" }}
                >
                  Print FG/SFG labels
                </Button>
              </div>
            </Tab>

            <Tab eventKey="BOC" title="BOC">
              {data.transaction_data_boc.length > 0 && (
                <Table responsive className="custom-table">
                  <thead>
                    <tr>
                      <th>Item Type</th>
                      <th>Item Number</th>
                      <th>Item Description</th>
                      <th>Item Level</th>
                      <th>Item Quantity</th>
                      <th>Item UOM</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.transaction_data_boc.map((row) => (
                      <tr key={row.id}>
                        <td>{row.item_type}</td>
                        <td>{row.item_number}</td>
                        <td>{row.item_description}</td>
                        <td>{row.item_level}</td>
                        <td>{row.item_qty}</td>
                        <td>{row.uom}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}

              <Form.Select
                style={{ width: "200px", position: "absolute", right: "90px" }}
                value={selectedLabel}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select a Label
                </option>
                {data.labels
                  .filter((label) => label.label_type === "BOC")
                  .map((label) => (
                    <option key={label.id} value={label.label_name}>
                      {label.label_name}
                    </option>
                  ))}
              </Form.Select>

              <Form.Control
                type="number"
                name="boc_label_count"
                className="custom-input2  "
                placeholder="Label Count"
                onChange={handleLabelCountChange}
                disabled
                value={data2.length}
                required
              />
              {/* {console.log(data2)} */}

              <Button
                variant="dark"
                onClick={handleBackSubmit}
                type="button"
                className="user-register-back-btn"
                size="sm"
                style={{
                  backgroundColor: "#243847",
                  borderColor: "#243847",
                  position: "relative",
                  top: "40px",
                }}
              >
                Back
              </Button>

              <div className="right-align" style={{ paddingBottom: "30px" }}>
                <Button
                  variant="dark"
                  onClick={handleSubmitBOC}
                  type="submit"
                  className="custom-button"
                  size="sm"
                  style={{
                    backgroundColor: "#243847",
                    borderColor: "#243847",
                    position: "relative",
                    bottom: "20px",
                  }}
                >
                  Print BOC Labels
                </Button>
              </div>
            </Tab>
          </Tabs>
        </Container>
         <br />
         <br />
      </div>
    </>
  );
}
