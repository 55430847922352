import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Container from "react-bootstrap/esm/Container";
import Table from "react-bootstrap/esm/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { API } from "../api/API.js";
import { alertSuccess, alertWarning } from "../alert.js";
import "../css/custom.css";

const LabelPrintInactive = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const api = new API();
  const productionOrderID = location.state?.productionOrderID;
  const productionOrderNumber = location.state?.productionOrderNumber;
  const jobTransactionNumber = location.state?.jobTransactionNumber;
  const productionOrderQty = location.state?.productionOrderQty;
  const status = location.state?.status;

  const [labels, setLabels] = useState([]); // To store the list of labels
    
  const [key, setKey] = useState("FG/SFG"); // Active tab (FG/SFG or BOC)
  const [selectedStatus, setSelectedStatus] = useState('Active');

  const [count, setCount] = useState(0);
  const [checked, setChecked] = useState(false);
  const [trigger, setTrigger] = useState(false);
  

  const [data, setData] = useState({
    transaction_data_fg_sfg: [],
    transaction_data_boc: [],
    print_status: "",
    inward_status: '',
    status: "",
    label_print_status: "",
    boc_print_status: "",
  });
  
  useEffect(() => {
    async function get() {
      const transaction = await api.production_transaction_get(productionOrderID);
  
      setData(transaction);
    }

    get();
  }, []);


  const handleChange = (event) => {
      setSelectedStatus(event.target.value);
  };

  const handleBackSubmit = () => {
    navigate(-1);
  };

  const handleDelete = () => {
    api.production_header_delete(productionOrderID, navigate);
  };

  const handleSubmit = () => {

    if(selectedStatus == 'Active'){
      alertWarning('Please change the status!');
    }
    else{
      api.production_header_inactive(productionOrderID, navigate);
    }

  };


  return ( 
      <div className="labelprintinactive">
          <div className="basic" style={{ position: "relative", marginTop: "1px" }}>
      <Container>
        <br />
        <span style={{ paddingRight: "30px" }}>
          <b> Production Order Number: {productionOrderNumber} </b>
        </span>
        <span style={{ paddingRight: "30px" }}>
          <b> Job Transaction Number: {jobTransactionNumber} </b>
        </span>
        <span style={{ paddingRight: "30px" }}>
          <b> Production Order Quantity: {productionOrderQty} </b>
        </span>
        <div>
          <div>

            {data.print_status === 'no' && (
              <Button
                variant="dark"
                onClick={handleDelete} // Implement your delete handler function if needed
                type="button"
                size="sm"
                style={{
                  backgroundColor: '#243847',
                  borderColor: '#243847',
                  position: 'absolute',
                  right: '100px',
                  top: '22px',
                }}
              >
                Delete
              </Button>
            )}

            
            {data.inward_status === 'no' && data.print_status !== 'no' && data.status !== 'inactive' && (
              <span>
                <Form.Select
                  style={{
                    width: '110px',
                    position: 'absolute',
                    right: '200px',
                    top: '20px',
                  }}
                  value = {selectedStatus}
                  onChange={handleChange}
                  required
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Select>
              </span>
            )}

          </div>
      </div>

        <Tabs
          defaultActiveKey="FG/SFG"
          id="uncontrolled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="custom-mb mb-3"
        >
          {/* FG/SFG Tab */}
          <Tab eventKey="FG/SFG" title="FG/SFG">
            {data.transaction_data_fg_sfg.length > 0 && (
              <Table responsive className="custom-table">
                <thead>
                  <tr>
                    <th>
                    </th>
                    <th>Item Type</th>
                    <th>Item Number</th>
                    <th>Item Description</th>
                    <th>Item Level</th>
                    <th>Item Quantity</th>
                    <th>Item UOM</th>
                  </tr>
                </thead>

                <tbody>
                  {data.transaction_data_fg_sfg.map((row) => (
                    <tr key={row.id}>
                      <td>
                      </td>
                      <td>{row.item_type}</td>
                      <td>{row.item_number}</td>
                      <td>{row.item_description}</td>
                      <td>{row.item_level}</td>
                      <td>{row.item_qty}</td>
                      <td>{row.uom}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}


            <Button
              variant="dark"
              onClick={handleBackSubmit}
              type="button"
              className="user-register-back-btn"
              // size="sm"
              style={{
                backgroundColor: "#243847",
                borderColor: "#243847",
                position: "relative",
                top: "20px",
              }}
            >
              Back
            </Button>
            {data.inward_status === 'no' && data.print_status !== 'no' && data.status == 'active' && (
              <div className="right-align">
                  <Button
                    variant="dark"
                    type="submit"
                    className="custom-button"
                    onClick={handleSubmit}
                    style={{
                      backgroundColor: "#243847",
                      borderColor: "#243847",
                      width:"25%",
                      position:"relative",
                      bottom:"50px"
                    }}
                  >
                    Submit
                  </Button>
                </div>
            )}

          </Tab>

          {/* BOC Tab */}
          <Tab eventKey="BOC" title="BOC">
            <Table responsive className="custom-table">
              <thead>
                <tr>
                  <th>Item Type</th>
                  <th>Item Number</th>
                  <th>Item Description</th>
                  <th>Item Level</th>
                  <th>Item Quantity</th>
                  <th>Item UOM</th>
                </tr>
              </thead>
              <tbody>
                {data.transaction_data_boc.map((row) => (
                  <tr key={row.id}>
                    <td>{row.item_type}</td>
                    <td>{row.item_number}</td>
                    <td>{row.item_description}</td>
                    <td>{row.item_level}</td>
                    <td>{row.item_qty}</td>
                    <td>{row.uom}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <Button
              variant="dark"
              onClick={handleBackSubmit}
              type="button"
              className="user-register-back-btn"
              // size="sm"
              style={{
                backgroundColor: "#243847",
                borderColor: "#243847",
                position: "relative",
                top: "40px",
              }}
            >
              Back
            </Button>

          </Tab>
        </Tabs>
      </Container>
      <br />
      <br />
    </div>
      </div>
  );

}
 
export default LabelPrintInactive;