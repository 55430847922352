import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { API } from '../api/API';

const RolePage = () => {
  const [data, setData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const api = new API();

  useEffect(() => {
    async function get_all_role() {
      const response = await api.roles_get_all_role();
      console.log('Fetched roles:', response);
      setData(response);
    }

    get_all_role();
  }, []);

  // Check if the user is an admin
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails && userDetails.username === 'admin1') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  const columns = [
    { name: 'SNO', selector: (row, index) => index + 1, sortable: false },
    { name: 'Role', selector: row => row.name, sortable: true },
    // ...(isAdmin ? [{
    //   name: 'Action',
    //   cell: row => (
    //     <Button
    //       variant="dark"
    //       size="sm"
    //       style={{ backgroundColor: '#243847', borderColor: '#243847' }}
    //       onClick={() => handleEditClick(row.name)}
    //       disabled={row.name === 'Admin'}  // Disable button if role is Admin
    //     >
    //       Edit
    //     </Button>
    //   ),
    //   // width: '100px'
    // }] : [])
  ];

  const handleCreateClick = () => {
    console.log('Create button clicked');
    navigate("/user-role");
  };

  const handleEditClick = (rolename) => {
    // console.log(`Edit button clicked for role ID: ${id}`);
    navigate(`/edit-role/${rolename}`);
  };

  const handleBackSubmit = () => {
    navigate("/");
  };

  return (
    <div className='basic' style={{ position: "relative", marginTop: "40px" }}>
      <Container className='role-container' 
      style={{ width: "567px" }}
      >
        <DataTable
          className="data-table-container"
          columns={columns}
          data={data}
          pagination
          highlightOnHover
          responsive
        />
      </Container>
      <Button
                variant="dark"
                onClick={handleBackSubmit}
                type="button" // Change to button to avoid submitting the form
                className="user-register-back-btn"
                size="sm"
                style={{ backgroundColor: '#243847', borderColor: '#243847', position: 'relative', left: '30%' }}
              >
                Back
              </Button>

              {/* {isAdmin && (
        <>
      <Button
            style={{ backgroundColor: '#243847', borderColor: '#243847' }}
        variant="dark"
        onClick={handleCreateClick}
        type="submit"
        className="role-create-btn"
        size="sm"
      >
        Create
      </Button>
      </> 
    )}*/}
    </div>
  );
};

export default RolePage;
